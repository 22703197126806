<template>
  <div class="wrap-edit">
    <div class="edit-header">
      <span>退件回执</span>
      <div>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button type="warning" @click="print">打印</el-button>
      </div>
    </div>
    <el-card class="edit-container">
      <el-form
        ref="baseform"
        label-position="left"
        :model="form"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="编号:">
              <el-input v-model="form.bh" disabled></el-input>
            </el-form-item>
            <el-form-item label="申报类型:">
              <el-input v-model="form.sblx" disabled></el-input>
            </el-form-item>
            <el-form-item label="建设单位:">
              <el-input v-model="form.jsdw" disabled></el-input>
            </el-form-item>
            <el-form-item label="项目名称:">
              <el-input v-model="form.xmmc" disabled></el-input>
            </el-form-item>
            <el-form-item label="建设位置:">
              <el-input v-model="form.jswz" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人:">
              <el-input v-model="form.lxr" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号:">
              <el-input v-model="form.sjh" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="退件原因:">
              <el-input
                v-model="form.TJYY"
                type="textarea"
                :rows="3"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退件人:">
              <el-input v-model="form.JBRXM" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="退件日期:">
              <el-date-picker
                style="width: 100%"
                v-model="form.JBRQ"
                :clearable="false"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <div id="printable-form" style="font-family: 'SimSun';font-size:14px;padding: 0 100px">
      <div style="font-size:25px;text-align: center">退件回执</div>
      <div class="right-align" style="margin-bottom: 20px">
        编号：<span style=";font-size:14px">{{ form.bh }}</span>
      </div>
      <p>
        申报类型：<span>{{ form.sblx }}</span>
      </p>
      <p>
        建设单位：<span>{{ form.jsdw }}</span>
      </p>
      <p>
        项目名称：<span>{{ form.xmmc }}</span>
      </p>
      <p>
        建设位置：<span>{{ form.jswz }}</span>
      </p>
      <p>
        联系人：<span>{{ form.lxr }}</span>
      </p>
      <p>
        手机号：<span>{{ form.sjh }}</span>
      </p>
      <div style="word-wrap:break-word;display: flex;justify-content: flex-start">
        <div style="margin-right: 5px">退件原因:</div>
        <div style="margin-top: -14px"> <pre v-text="form.TJYY"></pre></div>
      </div>
      <div class="right-align" style="margin-top: 40px">
        退件人：<span>{{form.JBRXM}}</span>
      </div>
      <p class="right-align">
        退件日期：<span>{{ getDate(form.JBRQ) }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import print from "print-js";
import dayjs from 'dayjs';
export default {
  name:'tjhz',
  data() {
    return {
      form: {
        Id: "",
        BJID: "",
        bh: "",
        sblx: "",
        jsdw: "",
        xmmc: "",
        jswz: "",
        lxr: "",
        sjh: "",
        TJYY: "",
        JBRXM: "",
        JBRQ: new Date(),
      },
    };
  },
  methods: {
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY年MM月DD日')
      return a
    },
    async fetch() {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
              "/api/YW/Gettjhz?BJID=" +
              this.form.BJID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data;
              if(this.form.JBRQ==''||this.form.JBRQ==null){
                this.form.JBRQ=new Date()
              }
            } else {
              throw response.data.ErrorMessage;
            }
          });
        await this.getbjsx(this.form.BJID);
      } catch (error) {
        console.log(error);
      }
    },
    onSubmit() {
      let me = this;
      if (this.checkForm()) {
        try {
          this.$ajax
            .post(
              this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddOrUpdatetjhz",
              this.form
            )
            .then(function (response) {
              if (response.data.IsSuccess) {
                me.form.Id = response.data.Data;
                ElMessage({
                  type: "success",
                  message: "保存成功!",
                });
              } else {
                ElMessage({
                  type: "info",
                  message: "保存失败!",
                });
                throw response.data.ErrorMessage;
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    getbjsx(Id) {
      return this.$ajax
        .get(this.$appConfig.apiUrls.ywxtApi + "/api/YW/Getlahz?Id=" + Id)
        .then(async (response) => {
          if (response.data.IsSuccess) {
            const sbsxList = await this.getsbsxList();
            const data = response.data.Data;
            this.form.bh = data.ZBH;
            this.form.jsdw = data.JSDW;
            this.form.xmmc = data.XMMC;
            this.form.jswz = data.JSWZ;
            this.form.lxr = data.LXR;
            this.form.sjh = data.SJH;
            this.form.sblx = sbsxList[data.SBLX];
          } else {
            throw response.data.ErrorMessage;
          }
        })
        .catch((e) => {
          throw e;
        });
    },
    getsbsxList() {
      return this.$ajax
        .get(this.$appConfig.apiUrls.flowApi + "/api/WorkFlow/GetWorkFlowList")
        .then((response) => {
          if (response.data.IsSuccess) {
            return response.data.Data;
          } else {
            throw response.data.ErrorMessage;
          }
        })
        .catch((e) => {
          throw e;
        });
    },
    checkForm() {
      let b = true;
      let f = this.form;
      if (b) b = this.checkinput(f.TJYY, "退件原因");
      if (b) b = this.checkinput(f.JBRXM, "退件人");
      if (b) b = this.checkinput(f.JBRQ, "退件日期");
      return b;
    },
    checkinput(val, mess) {
      if (!val) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },
    print() {
      console.log(this.form.TJYY)
      print({
        printable: "printable-form",
        type: "html",
        scanStyles: false,
        css: "/static/print-style.css",
        documentTitle: "",
      });
    },
  },
  mounted() {
    this.form.BJID = this.$route.query.instanceId ?? "";
    this.fetch();
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  margin-bottom: 10px;
}
.edit-container {
  margin-top: 0px;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}

#printable-form {
  display: none;
}
</style>
